import getMatch from "@/game-deadlock/fetches/match.mjs";
import getProfileHeroStats from "@/game-deadlock/fetches/profile-hero-stats.mjs";
import getSteam from "@/game-deadlock/fetches/steam.mjs";
import { steam3to64 } from "@/util/steam.mjs";

export default async function fetchData(
  params: FixedLengthArray<string, 2>,
): Promise<void> {
  const [matchId, steamId] = params;
  await Promise.all([
    getSteam({
      steamId3: steamId,
      steamId64: steam3to64(steamId),
    }),
    getProfileHeroStats({ steamId }),
    (async (): Promise<void> => {
      const match = await getMatch({ matchId });
      if (!Array.isArray(match?.players)) return;
      // The data being fetched are below the fold in this page and can update async
      for (const { account_id: steamId3 } of match.players)
        getSteam({
          steamId3,
          steamId64: steam3to64(steamId3.toString()),
        });
    })(),
  ]);
}
